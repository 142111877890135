import { useContext } from 'react';
import { CookieContext } from './CookieProvider';

const ConsentGate = ({ children, policy }) => {
  const { cookieConsent } = useContext(CookieContext);
  let consent = null;

  try {
    consent = cookieConsent?.find(c => c.name === policy)?.consent;
  } catch (error) {
    console.error('Error getting cookie consent', error);
  }

  if (consent !== true) {
    return null;
  }

  return children;
};

export default ConsentGate;
