import {
  FUNCTIONS_API_URL,
  ENVIRONMENT,
  getAuthToken,
  SOURCE
} from 'config/settings';
import { store } from 'store';

let tracked = [];

const TrackingEventConstants = {
  SwitchedSchool: 'Switched School',
  LoggedIn: 'Logged In',
  ClassCreationStarted: 'Class Creation Started',
  ClassDetailsAdded: 'Class Details Added',
  ClassTeachersAdded: 'Class Teachers Added',
  ClassDetailsEdited: 'Class Details Edited',
  ClassTeachersEdited: 'Class Teachers Edited',
  ClassCreationFinished: 'Class Creation Finished',
  ClassStudentInvitesStarted: 'Class Student Invites Started',
  ClassStudentInvitesFinished: 'Class Student Invites Finished',
  TopicSearched: 'Topic Searched',
  TopicShared: 'Topic Shared',
  AssignmentViewed: 'Assignment Viewed',
  AssignmentEdited: 'Assignment Edited',
  AssignmentResultsViewed: 'Assignment Results Viewed',
  AssignmentQuestionResultsViewed: 'Assignment Question Results Viewed',
  AssignmentQuizResultsDownloaded: 'Assignment Quiz Results Downloaded',
  AssignmentClassResultsDownloaded: 'Assignment Class Results Downloaded',
  AssignmentQuestionInfoDownloaded: 'Assignment Question Info Downloaded',
  ViewedDemoResults: 'Viewed Demo Results',
  YearGroupSelection: 'Year Group Selection',
  // Teacher activation funnel events
  SetWorkClicked: 'Set Work Clicked',
  SelectedACollection: 'Selected A Collection',
  SearchForATopicTextSearch: 'Search For A Topic Text Search',
  SearchForATopicSubjectSelected: 'Search For A Topic Subject Selected',
  SearchForATopicTopicSelected: 'Search For A Topic Topic Selected',
  TopicViewed: 'Topic Viewed',
  PresentTopicToClass: 'Present Topic To Class',
  TopicSkillSelected: 'Topic Skill Selected',
  TopicDiagnoseQuestionSelected: 'Topic Diagnose Question Selected',
  TopicResolveVideoSelected: 'Topic Resolve Video Selected',
  ExplainMistakeVideoSelected: 'Explain The Mistake Video Selected',
  TopicFluencyPracticeQuestionSelected:
    'Topic Fluency Practice Question Selected',
  TopicIntelligentPracticeQuestionSelected:
    'Topic Intelligent Practice Question Selected',
  TopicStretchQuestionSelected: 'Topic Stretch Question Selected',
  TopicAssignThisQuizSelected: 'Topic Assign This Quiz Selected',
  TopicQuizAddedSelected: 'Topic Quiz Added Selected',
  TopicChooseClassSelected: 'Topic Choose Class Selected',
  TopicAssignToClassSelected: 'Topic Assign To Class Selected',
  RetrievalPracticesAssigned: 'Retrieval Practices Assigned',
  TopicAssigned: 'Topic Assigned',
  // Results & Analysis
  DashboardUpcomingAssignmentsTabClicked:
    'Dashboard Upcoming Assignments Tab Clicked',
  DashboardDoneAssignmentsTabClicked: 'Dashboard Done Assignments Tab Clicked',
  AnalyticsLoaded: 'Analytics Loaded'
};

const track = async (action, values = {}, isTrackOnce = false) => {
  const state = store.getState();

  if (isTrackOnce && tracked.includes(action)) return;

  const { userId, schoolId, teacherId } = {
    ...state.user,
    ...state.school
  };

  !tracked.includes(action) && tracked.push(action);

  postEvent(
    action,
    {
      action,
      teacher_id: teacherId,
      school_id: schoolId,
      classroom_school_id: schoolId,
      host: window.location.host,
      page: window.location.pathname,
      source: 'teacher_' + ENVIRONMENT,
      ...values
    },
    userId
  );
};

const postEvent = (action, data, userId) => {
  if (!userId) return;

  try {
    const body = {
      action,
      userId,
      properties: data,
      source: SOURCE
    };

    fetch(`${FUNCTIONS_API_URL}/api/v3/users/${userId}/tracking-events`, {
      method: 'POST',
      body: JSON.stringify(body),
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${getAuthToken()}`,
        source: 'teacher_' + ENVIRONMENT
      }
    });
  } catch (e) {
    console.error(e);
  }
};

const tracking = {
  track: (action, values) => track(action, values, false),
  trackOnce: (action, values) => track(action, values, true),
  trackOverride: (action, data, userId) => postEvent(action, data, userId),
  eventNames: TrackingEventConstants
};

export default tracking;
