import murmur from 'murmurhash-js';

const testMurmur3HashAB = (action, studentId) => {
  console.log('testMurmur3HashAB', murmur.murmur3(action ?? 'Seed', studentId));
  let a = 0;
  let b = 0;
  // As of 2021-11-30 these are the min max student Ids to check hashes for.
  let studentIdMin = 1045455;
  let studentIdMax = 2535949;
  for (let i = studentIdMin; i < studentIdMax; i++) {
    let hash = murmur.murmur3(action ?? 'Seed', i);
    if (hash % 2) {
      a++;
    } else {
      b++;
    }
  }
  console.log('testMurmur3HashAB', a, b);
};

const testCustomHashAB = (action, studentId) => {
  console.log('testCustomHashAB', action, getHashCode(studentId));
  let a = 0;
  let b = 0;
  // As of 2021-11-30 these are the min max student Ids to check hashes for.
  let studentIdMin = 1045455;
  let studentIdMax = 2535949;
  for (let i = studentIdMin; i < studentIdMax; i++) {
    let hash = getHashCode(i);
    if (hash % 2) {
      a++;
    } else {
      b++;
    }
  }
  console.log('testCustomHashAB', a, b);
};

export const getHashCode = input => {
  if (!input) return -1;
  input = `${input}`;
  let hash = 0,
    i,
    chr;
  if (input.length === 0) return hash;
  for (i = 0; i < input.length; i++) {
    chr = input.charCodeAt(i);
    hash = (hash << 5) - hash + chr;
    hash |= 0; // Convert to 32bit integer
  }
  return Math.abs(hash);
};

export const hashObjectValues = obj => {
  const values = Object.values(obj).join('');
  return murmur.murmur3(values);
};

export const hashCodeHelpers = {
  getHashCode,
  testCustomHashAB,
  testMurmur3HashAB,
  hashObjectValues
};
